<script setup>
definePageMeta({
  layout: 'moto-layout',
});

const backend = useBackend();
const route = useRoute();
const router = useRouter();

const itemsPerPage = 18;
const currentPage = computed(() => parseInt(route.query.page) || 1);
const searchQuery = computed(() => route.query.search || '');
const pageData = reactive({
  articles: { items: [] },
});

const totalItems = ref(0);
const { data: articlesData, pending: articlesPending, error: articlesError } = await useAsyncData(
  `search-${searchQuery.value}-${currentPage.value}`,
  () => {
    const backendPage = currentPage.value - 1;
    return backend.getMotoSearchPageData(searchQuery.value, backendPage);
  },
  {
    watch: [currentPage, searchQuery],
  }
);

watch(
  () => articlesData.value,
  (newVal) => {
    if (newVal && newVal.data && newVal.data.articles) {
      pageData.articles.items = newVal.data.articles.items || [];
      totalItems.value = newVal.data.articles.totalCount || 0;
    } else {
      pageData.articles.items = [];
      totalItems.value = 0;
    }
  },
  { immediate: true }
);

const loaded = computed(() => !articlesPending.value && articlesData.value);

useHeadSafe(() => {
  const canonicalUrl = `https://vehis.pl${route.fullPath}`;
  return {
    title: 'Moto Vehis - najlepszy portal motoryzacyjny w Polsce',
    meta: [
      { name: 'description', content: 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź' },
      { property: 'og:title', content: 'Moto Vehis - najlepszy portal motoryzacyjny w Polsce' },
      { property: 'og:description', content: 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź' },
      { property: 'og:type', content: 'article' },
      { property: 'og:image', content: 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź'},
    ],
    link: [
      { rel: 'canonical', href: canonicalUrl },
    ],
  };
});
function goToPage(newPage) {
  const basePath = route.path.split('?')[0];

  if (newPage === 1) {
    const { page, ...restQuery } = route.query;
    router.push({ path: basePath, query: { ...restQuery } });
  } else {
    router.push({ path: basePath, query: { ...route.query, page: newPage } });
  }
}
</script>

<template>
  <Container no-paddings>
    <div v-if="loaded">
      <LayoutColumns>
        <LayoutMainColumn noflex>
          <BlogBreadCrumbs
            :breadcrumbs="[
              { label: 'Strona główna', to: '/moto' },
              { label: 'Wyniki wyszukiwania', to: route.fullPath }
            ]"
          />
          <MainContentHeader class="font-extralight">
            Twoje wyszukiwanie: <span class="font-normal">{{ searchQuery }}</span>
          </MainContentHeader>
          <div
            v-for="(group, groupIndex) in Math.ceil(pageData.articles.items.length / 6)"
            :key="groupIndex"
          >
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
              <template
                v-for="(item, index) in pageData.articles.items.slice(
                  groupIndex * 6,
                  groupIndex * 6 + 6
                )"
                :key="`article-${groupIndex}-${index}`"
              >
                <BlogMainCard :index="index" :item="item" />
              </template>
            </div>
            <BannerMain :bannerName="'moto-srodek-1440x300'"
              class="!mx-0 my-12"
              v-if="(groupIndex + 1) * 6 < pageData.articles.items.length"
            />
          </div>
          <BlogPagination
            :total-items="totalItems"
            :items-per-page="itemsPerPage"
            :current-page.sync="currentPage"
            @page-changed="goToPage"
          />
        </LayoutMainColumn>
        <LayoutSideColumn class="hidden lg:block pb-4 !bg-transparent">
          <BannerSidebar :bannerSlot="'moto-wyszukiwarka-300x600'" class="my-6" />
        </LayoutSideColumn>
      </LayoutColumns>
    </div>
    <div v-else>
      <p>Ładowanie wyników wyszukiwania...</p>
    </div>
  </Container>
</template>
